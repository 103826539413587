import React from 'react'
import './ShowPassword.css'

interface ShowPasswordProps {
  onPasswordTypeChange: () => void
  passwordType: string
  displayText: string
}

const ShowPassword: React.FC<ShowPasswordProps> = ({
  onPasswordTypeChange,
  passwordType,
  displayText
}) => {
  return (
    <div className="row">
      <div className="col-auto set-show-checkbox">
        <div className="form-check">
          <input
            type="checkbox"
            checked={passwordType === 'text'}
            onChange={onPasswordTypeChange}
            className="form-check-input"
            style={{ borderColor: 'black' }}
            tabIndex={-1}
          />
        </div>
      </div>
      <div className="col set-show-password">
        <p className={passwordType === 'text' ? 'mb-1 bold-text' : 'mb-1'}>
          {displayText}
        </p>
      </div>
    </div>
  )
}

export default ShowPassword
