import React from 'react'
import { Link } from 'react-router-dom'
import { formatName, handleDocumentation, handleExtension, navigatePDFChat } from '../../../utils/helpers'
import { CANDOO_ADV_SITE } from '../../../utils/constants'

export interface User {
  initials: string
  name: string
  handleLogout: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
export const Session: React.FC<User> = ({ name }) => {
  return (
    <div className='mt-5'>
      <div className="col-md-12 secondary">
        <div className="p-3 box-style">
          <div className="general-box">
            <h3 className="justify-items-center">
              Welcome {formatName(name)},
            </h3>
            <p className='mb-2'>✅ Your CanDoo session is currently active</p>
            <p className='mt-2'>New to CanDoo? Get started with our interactive documentation below</p>
            <button className='btn btn-color-secondary w-100 mt-2 mb-4' onClick={handleDocumentation}>Documentation</button>
            <div className='row mb-3 justify-content-center'>
            <p className='mb-2'>You can now chat with PDF docs. Try our new feature now!</p>
            <div className="row">
              <div className="col">
                <button
                  className="btn w-100 rounded-9 mt-1 mb-0 btn-color-main"
                  onClick={navigatePDFChat}
                >
                  Checkout PDF Chat
                </button>
              </div>
              <div className="col">
                <button
                  className="btn w-100 rounded-9 mt-1 mb-0 btn-color-secondary"
                  onClick={handleExtension}
                >
                  Continue with CanLII
                </button>
              </div>
            </div>
            </div>
            <p className='para'>
              Want to know more about CanDoo? Swing by our{' '}
              <Link to={CANDOO_ADV_SITE} target="_blank">
                site
              </Link>{' '}
              and get the scoop!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
