import React from 'react'
import YouTube, { type YouTubeEvent } from 'react-youtube'
interface VideoPlayerProps {
  videoUrl: string
}
export default function VideoPlayer ({
  videoUrl
}: VideoPlayerProps): JSX.Element {
  const getVideoId = (url: string): string => {
    const match =
      url.match(/[?&]v=([^&#]*)/) ?? url.match(/youtu\.be\/([^?&#]*)/)
    return match == null ? '' : match[1]
  }
  const videoId = getVideoId(videoUrl)
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1
    }
  }
  const onReady = (event: YouTubeEvent): void => {
    event.target.pauseVideo()
  }
  return (
    <div>
      {videoId != null
        ? (
        <YouTube videoId={videoId} opts={opts} onReady={onReady} />
          )
        : (
        <p>Invalid YouTube URL</p>
          )}
    </div>
  )
}
