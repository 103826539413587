import React from 'react'
import { Link } from 'react-router-dom'
import './Inactive.css'

export const Inactive: React.FC = () => {
  return (
    <div className="col-md-12 margin-inactive inactive-box">
      <div className="p-4 box-style">
        <div className="container margin-base">
          <h3 className="general-box-default">You haven&apos;t logged in</h3>
          <Link
            className="btn w-100 rounded-9 mt-1 mb-0 btn-color-main"
            to="/login"
          >
            Login Now
          </Link>
          <p className="advertise">
            Discover the Power of <Link to="https://candoo.ai/">CanDoo AI</Link> -
           Legal Assistant you can trust
          </p>
        </div>
      </div>
    </div>
  )
}
