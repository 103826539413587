import * as React from 'react'
import './NewsFeed.css'
import CustomModal from '../CustomModal/CustomModal'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
export default function NewsFeed (): JSX.Element {
  return (
    <div className="news-feed-container">
      <div className="updates-container container  p-4">
        <div className="header">
          <h1 className="text-center">News Feed 📰</h1>
        </div>
        <div className="content">
          <p>
            We are thrilled to announce the release of our latest update for the
            CanDoo AI Platform Chrome Extension: version 1.10.5! This update
            brings a host of exciting features and enhancements designed to
            elevate your experience to new heights.
          </p>
          <h2>Improvements</h2>
          <div>
            <h4 className="links">1. Advances to our Memo Generator</h4>
          </div>
          <p>
            We’ve received plenty of feedback on our format and performance of
            our memos, so we’ve put that to work. Compared to our previous
            version, we’ve improved the conciseness, clarity, and
            comprehensiveness score of our memos by an average of 7.6%. Case
            memos now present long lists of facts, comprehensive issues, and
            digestible summaries for each case.
          </p>
          <div className="video-player">
            <VideoPlayer videoUrl="https://youtu.be/N71XIWLk_HA?si=QZeopBHaiYTemu2a" />
          </div>

          <h4 className="links">
            2. Clickable References with Memo and Case Chat
          </h4>
          <p>
            References are now clickable! Moreover, we’ve improved the quality
            of answers while significantly boosting the precision of our
            references by 15%, ensuring you get to the most relevant paragraphs
            as soon as possible.
          </p>

          <h4 className="links">3. A Fresh New UI</h4>
          <p>
            We have redesigned user interface (UI) to improve usability and
            accessibility. With a cleaner layout, you can focus on the plugin’s
            powerful features with greater ease.
          </p>
          <div className="image-button-container">
            <button className="mb-3">
              <img
                className="img-button"
                src="https://storage.googleapis.com/version-updates/case-chat.png"
              ></img>
              <CustomModal displayContent="https://storage.googleapis.com/version-updates/case-chat.png" />
            </button>
          </div>
          <h2>New Features</h2>
          <h4 className="links">1. Seamless SSO with Google and Microsoft</h4>
          <p>
            Registering and logging in has become easier. Our plugin now
            supports single sign-on (SSO) with both Google and Microsoft
            accounts, streamlining the login process and saving you time.
          </p>
          <h4>
            <a
              className="links"
              href="https://storage.googleapis.com/version-updates/memoFeedback.png"
            >
              2. Simplified Feedback Mechanism with Memo
            </a>
          </h4>
          <p>
            Since CanDoo is free, you can pay us with your feedback. Rate our
            memos on a scale of 1 to 5 on its format, accuracy and helpfulness,
            and/or directly send us your comments from our memo feedback pop-up.
          </p>
          <div className="image-button-container">
            <button className="mb-3">
              <img
                className="img-button"
                src="https://storage.googleapis.com/version-updates/memoFeedbackCollection.png"
              ></img>
              <CustomModal displayContent="https://storage.googleapis.com/version-updates/memoFeedbackCollection.png" />
            </button>
          </div>
          <h4>
            <a
              className="links"
              href="https://storage.googleapis.com/version-updates/PDFchat.png"
            >
              3. Introducing CanDooPDF: A Powerful New PDF Analyzer
            </a>
          </h4>
          <p>
            CanDooPDF supports analyzing multiple PDFs simultaneously,
            extracting and consolidating your files with precision. We’ve built
            this to accommodate for those wanting to us our memo/chat features
            outside of the CanLII website itself.
          </p>
          <p>
            Upgrade today and transform your workflow with these powerful new
            capabilities! Don’t miss out on the opportunity to experience the
            difference.
          </p>
          <div className="image-button-container">
            <button className="mb-3">
              <img
                className="img-button"
                src="https://storage.googleapis.com/version-updates/PDFchat.png"
              ></img>
              <CustomModal displayContent="https://storage.googleapis.com/version-updates/PDFchat.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
