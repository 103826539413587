import React from 'react'
import LogoIcon from '../../../assests/images/candoo-light-logo.png'
import './Logo.css'

interface LogoProps {
  customText?: string
}
export default function Logo ({ customText }: LogoProps): JSX.Element {
  return (
    <div className="master-logo">
      <div className={`logo ${customText != null ? '' : 'mt-5'}`}>
        <img src={LogoIcon} className="image-logo"></img>
      </div>
      <h2 className="sub-header text-center">
      CanDoo AI - {customText ?? 'Legal Assistant you can trust!'}
      </h2>
    </div>
  )
}
