import React from 'react'
import { RegistrationStatus } from '../RegisterInterface'
import { emailAlreadyExists, usernameAlreadyExists, registrationFailed, ssoAccountExistsMessage, ssoPopupErrorMessage, userInfoNotFound } from '../../../utils/messageConstants'

interface MessagesProps {
  registrationStatus: RegistrationStatus
  givenEmail: string
  validations: {
    password: string
    terms: string
  }
}

// Displays necessary messages to the user upon Registration
const Messages: React.FC<MessagesProps> = ({
  registrationStatus,
  givenEmail,
  validations
}) => {
  return (
    <div className="error-messages-container">
      {registrationStatus === RegistrationStatus.Success && (
        <h4 className="success-message text-center">
          Thanks for registering with Formic. We have sent an email to{' '}
          {givenEmail} for the next steps.
        </h4>
      )}
      {registrationStatus === RegistrationStatus.EmailExists && (
        <h4 className="fail-message text-center">
          {emailAlreadyExists}
        </h4>
      )}
      {registrationStatus === RegistrationStatus.UsernameExists && (
        <h4 className="fail-message text-center">
          {usernameAlreadyExists}
        </h4>
      )}
      {registrationStatus === RegistrationStatus.Error && (
        <h4 className="fail-message text-center">
          {registrationFailed}
        </h4>
      )}
      {registrationStatus === RegistrationStatus.SsoAccountExists && (
        <h4 className="fail-message text-center">
          {ssoAccountExistsMessage}
        </h4>
      )}
      {registrationStatus === RegistrationStatus.SsoPopupError && (
        <h4 className="fail-message text-center">
          {ssoPopupErrorMessage}
        </h4>
      )}
      {registrationStatus === RegistrationStatus.SsoError && (
        <h4 className="fail-message text-center">
          {userInfoNotFound}
        </h4>
      )}
      {validations.password !== '' && (
        <h4 className="fail-message text-center">{validations.password}</h4>
      )}
      {validations.terms.length > 1 && (
        <h4 className="fail-message text-center">
          You haven&apos;t agreed to our terms and conditions
        </h4>
      )}
    </div>
  )
}

export default Messages
