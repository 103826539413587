import React from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import SideBar from './SideBar/SideBar'
import './CanDooDoc.css'
import { useAction } from '../../contexts/useAction'
const drawerWidth = 240
export const commonStyles = {
  backgroundColor: '#011320',
  color: 'white !important',
  borderStyle: 'ridge',
  borderColor: '#0b3c60',
  textAlign: 'center'
}

export const BtnColorMain = {
  backgroundColor: '#0b3c60',
  color: 'white',
  '&:hover': {
    backgroundColor: '#93670f'
  }
}
interface LayoutProps {
  children: JSX.Element
}
const CanDooDoc = ({ children }: LayoutProps): JSX.Element => {
  const action = useAction()
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`
        }}
      >
        <Toolbar sx={{ ...commonStyles, justifyContent: 'center' }}>
          <div>
            <h1 className="main-title">{action.activeTitle}</h1>
          </div>
        </Toolbar>
      </AppBar>
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  )
}

export default CanDooDoc
