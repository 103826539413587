import { emailAlreadyExists, usernameAlreadyExists } from './messageConstants'

export const handleErrors = (error: any): string => {
  const unknownError: string = 'Unknown Error. Please review your details'
  const inValidPassword: string = 'Invalid Email or Password'
  const response = error.response
  const statusCode = response?.status

  if (statusCode === 401) {
    return inValidPassword
  }
  if (
    response?.status === 409 &&
    response.data?.Error != null
  ) {
    const errorFlag = response.data.Error

    if (errorFlag === 'emailExists') return emailAlreadyExists
    if (errorFlag === 'usernameExists') return usernameAlreadyExists
  }
  console.error(error)
  return unknownError
}
