import React from 'react'
import './FooterHome.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
const FooterHome: React.FC = () => {
  const canDooFooter: string = 'https://candoo.ai/#footer'
  const canDooContact: string = 'https://candoo.ai/Contact.html'
  const canDooYoutube: string = 'https://www.youtube.com/watch?v=q-DvRq_U6OQ'

  const handleSubscribe = (): void => {
    window.open(canDooFooter, '_blank')
  }

  const handleContact = (): void => {
    window.open(canDooContact, '_blank')
  }

  return (
    <div className="footer-home justify-content-center">
      <br className="border" />
      <div className="row justify-content-center">
        <div className="row justify-content-center">
          <div className="col-3">
            <h2 className="newsletter">Join Our Newsletter</h2>
          </div>
          <div className="col-3">
            <h2 className="newsletter">Contact</h2>
          </div>
          <div className="col-3">
            <h2 className="newsletter">Tutorials</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-3">
            <p className="newsletter">
              We talk about everything Legal and AI. Great for anyone looking to
              learn more or trying to keep up with this lightning-paced space.
            </p>
          </div>
          <div className="col-3">
            <p className="newsletter">
              Let us know how your experience is going or if you encounter any
              challenges with CanDoo. We&apos;re here to provide assistance.
              Simply fill out your details, and we&apos;ll be ready to help you
              promptly.
            </p>
          </div>
          <div className="col-3">
            <p className="newsletter">
              Unsure where to begin? Explore our Tutorials to get started with
              the CanDoo Chrome extension.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-3">
            <button className="btn btn-color-main" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
          <div className="col-3">
            <button className="btn btn-color-main" onClick={handleContact}>
              Reach Out
            </button>
          </div>
          <div className="col-3">
            <a href={canDooYoutube} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                className="footer-icon"
                icon={faYoutube as IconProp}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterHome
