import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const AOLRequirements: React.FC = () => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="right"
      overlay={
        <Popover className="name-popover">
          <Popover.Header as="h3">About: Area Of Law</Popover.Header>
          <Popover.Body>
            <p>
              Providing your expertise will help us refine searches on CanLII to better suit your field.
            </p>
            <p>
              When entering your area of law, keep in mind the following:
            </p>
            <ul>
              <li>You may enter multiple fields of your legal expertise, separated by commas.</li>
              <li>Please ensure accuracy in spelling and clarity in specifying your expertise areas.</li>
            </ul>
            <p>Example: Corporate Law, Criminal Law, Family Law, etc.</p>
          </Popover.Body>
        </Popover>
      }
    >
      <button type="button" className='pwd-button' tabIndex={-1}>
        <FontAwesomeIcon className="pwd-icon" icon={faInfoCircle} />
      </button>
    </OverlayTrigger>
  )
}

export default AOLRequirements
