import { useState, useEffect } from 'react'

export default function Countdown (): {
  secondsLeft: number
  startCountDown: (seconds: number) => void
} {
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [countdownActive, setCountdownActive] = useState(false)

  useEffect(() => {
    if (secondsLeft <= 0 && countdownActive) {
      window.location.href = 'https://canlii.org'
      return
    }

    const timeout = setTimeout(() => {
      setSecondsLeft(secondsLeft - 1)
    }, 1000)
    return () => { clearTimeout(timeout) }
  }, [secondsLeft])

  function startCountDown (seconds: number): void {
    setSecondsLeft(seconds)
    setCountdownActive(true)
  }

  return { secondsLeft, startCountDown }
}
