export const GOOGLE: string = 'google'
export const MICROSOFT: string = 'microsoft'
export const userInfoNotFound: string = 'Unable to fetch User Information'
export const ssoPopupErrorMessage: string = 'SSO Popup has been closed. Please Retry!'
export const ssoAccountExistsMessage: string = 'Account Already Exists. Please login with the provider you registered with!'
export const invalidEmail: string = 'User Email Not Found'
export const registrationFailed: string = 'Please review your details. Registration Failed!'
export const emailAlreadyExists: string = 'Email already exists. Please try logging in.'
export const usernameAlreadyExists: string = 'Username is already taken. Please choose a different username'
export const passwordRequirementsError: string = 'Invalid password. Please verify that all password requirements are met.'
export const nameRequirementsError: string = 'Username Requirements are not met. Please ensure to adhere our username compliance.'
export const passwordResetSuccess: string = 'Your Password has been reset successfully. Please login now'
export const firebasePopUpError: string = 'auth/popup-closed-by-user'
export const firebaseClashErrorCode: string = 'auth/account-exists-with-different-credential'
export const ssoPopupError: string = 'ssoPopupError'
export const ssoAccountExists: string = 'ssoAccountExists'
export const microsoftProviderUrl: string = 'microsoft.com'
export const registrationComplete: string = 'User Registration is Complete. Please login now'
