import React from 'react'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { GOOGLE, MICROSOFT } from '../../../utils/messageConstants'
import './Providers.css'

interface ProvidersProps {
  handleAuth: (provider: string) => void
}

export default function Providers ({
  handleAuth
}: ProvidersProps): JSX.Element {
  return (
    <div className="row">
      <div className="col-6 sso-icons-google">
        <button
          type="button"
          className="provider-button"
          onClick={() => {
            handleAuth(GOOGLE)
          }}
        >
          <FontAwesomeIcon className="provider" icon={faGoogle as IconProp} />
        </button>
      </div>
      <div className="col-6 sso-icons-microsoft">
        <button
          type="button"
          className="provider-button"
          onClick={() => {
            handleAuth(MICROSOFT)
          }}
        >
          <FontAwesomeIcon
            className="provider"
            icon={faMicrosoft as IconProp}
          />
        </button>
      </div>
    </div>
  )
}
