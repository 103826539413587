import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Inactive } from '../../Reusable/Inactive/Inactive'
import CanDooLight from '../../../assests/images/candoo-light-logo.png'
import { useAction } from '../../../contexts/useAction'
import Timer from '../Timer/Timer'
import './Greetings.css'
import { getUserData } from '../../../api'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { CANLII_SITE } from '../../../utils/constants'
import { type ProfileInfo } from '../../../models/Defaults'

export default function Greetings (): JSX.Element {
  const action = useAction()
  const [username, setUserName] = useState<string>('')

  useEffect(() => {
    getUserData()
      .then((res) => {
        if (res.data.Status === 'Success') {
          const userDetails: ProfileInfo = res.data.userProfile
          setUserName(userDetails?.name)
        } else {
          action.setLoginStatus(false)
        }
      })
      .catch((err: any) => {
        console.error('Failed to fetch user data', err)
        action.setLoginStatus(false)
      })
  }, [])

  return (
    <div>
      {action.isLoggedIn && username.length > 0
        ? (
        <div className="row greet">
          <div className="col-auto">
            <img
              src={CanDooLight}
              className="light-logo mt-5"
              alt="CanDoo Light Logo"
            />
          </div>
          <div className="col-auto">
            <div className="justify-content-center mt-5">
              <div className="col-md secondary">
                <div className="p-4 box-style">
                  <h2 className="title-heading">Redirecting to CanLii</h2>
                  <p className="timer">
                    <Timer redirectURL={CANLII_SITE} />
                  </p>
                  <div className="general-box">
                    <h3 className="justify-items-center">
                      Welcome {username},
                    </h3>
                    <div>
                      <h4 className="session">
                        <VerifiedUserIcon /> Your CanDoo session is currently
                        active.
                      </h4>
                    </div>
                    <p className="redirect-style">
                        Please <Link to={CANLII_SITE}>click here</Link> if the
                        browser doesn&apos;t automatically redirect you
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        : (
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Inactive />
          </div>
        </div>
          )}
    </div>
  )
}
