import { useContext } from 'react'
import { ActionContext } from './ActionContext'
import { type Action } from '../actions/Action'

export function useAction (): Action {
  const action = useContext(ActionContext)

  if (action == null) {
    console.error('Missing ActionContext in UI tree')
    throw new Error('Missing ActionContext in UI tree')
  }

  return action
}
