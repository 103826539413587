import React from 'react'
import { Link } from 'react-router-dom'
import ShowPassword from '../../Reusable/ShowPassword/ShowPassword'
import PasswordRequirements from '../../Reusable/passwordRequirements/passwordRequirements'
import { RegistrationStatus, type RegisterViewProps } from '../RegisterInterface'
import Providers from '../../Reusable/Providers/Providers'
import { TERMS } from '../../../utils/constants'
import { Loading } from '../../Reusable/Loading/Loading'

export default function RegisterFormView ({
  formValues,
  handleSubmit,
  handleChanges,
  handleCheckboxChange,
  handlePasswordTypeChange,
  handleRoleChange,
  handlePasswordChange,
  checked,
  passwordType,
  handleSsoRegistration: handleSSORegistration,
  registrationStatus
}: RegisterViewProps): JSX.Element {
  return (
    <form onSubmit={handleSubmit}>
      {registrationStatus === RegistrationStatus.Loading && <Loading overlay={true} />}

      <div className="row justify-content-center mb-3">Signup with Google or Microsoft</div>
      <Providers handleAuth={handleSSORegistration} />
      <div className="row justify-content-center">or</div>
      <div className="mb-3">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="email">
              <strong>
                Email<span className="required">*</span>
              </strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              autoComplete="username"
              className="form-control"
              required
              onChange={(e) => {
                handleChanges(e, 'email')
              }}
              value={formValues.email}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="row profile-password-label">
          <div className="col-4 profile-style">
            <label htmlFor="password" className="register-password">
              <strong>
                Password<span className="required">*</span>
                <PasswordRequirements />
              </strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type={passwordType}
              placeholder="Enter Password"
              name="password"
              autoComplete="new-password"
              className="form-control"
              onChange={handlePasswordChange}
              value={formValues.password}
              required
            />
            <div>
              <ShowPassword
                onPasswordTypeChange={handlePasswordTypeChange}
                passwordType={passwordType}
                displayText="Show Password"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="role">
              <strong>
                Role<span className="required">*</span>
              </strong>
            </label>
          </div>
          <div className="col-8">
            <select
              name="role"
              className="btn w-100 btn-color-main"
              onChange={handleRoleChange}
              value={formValues.role}
            >
              <option value="lawyer">Lawyer</option>
              <option value="law student">Law Student</option>
              <option value="law faculty">Law Faculty</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row width-setter justify-content-center">
        <div className="col-auto">
          <div className="form-check">
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              className="form-check-input me-2"
              id="termsCheckbox"
              style={{ borderColor: 'black' }}
            />
            <label className="form-check-label mb-0" htmlFor="termsCheckbox">
              <a
                href={TERMS}
                target="_blank"
                rel="noopener noreferrer"
                className="forgot-pass"
              >
                <span className={checked ? 'bold-text' : ''}>
                  I agree to the terms and conditions
                </span>
              </a>
            </label>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="btn w-100 rounded-9 btn-color-secondary"
      >
        Sign Up
      </button>
      <div className="mt-2">
        <p className="mb-2 text-center">Already have an account? Login Instead</p>
      </div>
      <Link
        to="/login"
        className="btn w-100 rounded-9 text-decoration-none btn-color-main"
      >
        Login
      </Link>
    </form>
  )
}
