import React, { useState } from 'react'
import './Register.css'
import RegisterFormController from './Controller/RegisterFormController'
import Messages from './Messages/Messages'
import { RegistrationStatus } from './RegisterInterface'
import Logo from '../Reusable/Logo/Logo'
import Footer from '../Reusable/Footer/Footer'

export default function Register (): JSX.Element {
  const [registrationStatus, setRegistrationStatus] =
    useState<RegistrationStatus>(RegistrationStatus.Idle)
  const [givenEmail, setGivenEmail] = useState('')
  const [validations, setValidations] = useState({
    password: '',
    terms: ''
  })

  return (
    <div>
      <Logo />
      <div className="row center-error">
        <Messages
          registrationStatus={registrationStatus}
          givenEmail={givenEmail}
          validations={validations}
        />
      </div>
      <div className="container-ratio justify-content-center">
        <div className="margin-base register-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="secondary box-style position-relative">
                <div className="main-comp">
                <h4 className="heading-style">User Registration</h4>
                  <RegisterFormController
                    registrationStatus={registrationStatus}
                    setRegistrationStatus={setRegistrationStatus}
                    givenEmail={givenEmail}
                    setGivenEmail={setGivenEmail}
                    validations={validations}
                    setValidations={setValidations}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '5em' }}>
        <Footer />
      </div>
    </div>
  )
}
