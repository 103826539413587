/* This file holds all the interfaces and variables
associated with registration worflow */

export interface FormValues {
  name: string
  email: string
  password: string
  organization: string
  role: string
  firstName: string
  lastName: string
  areaOfLaw: string
}

export interface ssoRegister {
  email: string
  role: string
}

export enum RegistrationStatus {
  EmailExists,
  Success,
  SsoError,
  Error,
  Idle,
  Loading,
  UsernameExists,
  SsoPopupError,
  SsoAccountExists
}

export interface RegisterControllerProps {
  registrationStatus: RegistrationStatus
  setRegistrationStatus: React.Dispatch<
  React.SetStateAction<RegistrationStatus>
  >
  givenEmail: string
  setGivenEmail: React.Dispatch<React.SetStateAction<string>>
  validations: {
    password: string
    terms: string
  }
  setValidations: React.Dispatch<
  React.SetStateAction<{
    password: string
    terms: string
  }>
  >
}

export interface RegisterViewProps {
  formValues: FormValues
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  handleChanges: (
    e: React.ChangeEvent<HTMLInputElement>,
    parameter: string
  ) => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePasswordTypeChange: () => void
  handleRoleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  passwordType: string
  givenEmail: string
  registrationStatus: RegistrationStatus
  handleSsoRegistration: (provider: string) => void
}
