import React, { useState, useCallback, useEffect } from 'react'
import ProfileView from '../View/ProfileView'
import { updateUserProfile } from '../../../api'
import { type ProfileProps, topics, type UserProfile } from '../ProfileInterface'
import { defineNames } from '../../../utils/helpers'

const ProfileController: React.FC<ProfileProps> = ({ userProfile }) => {
  const [values, setValues] = useState<UserProfile>({
    username: userProfile.username,
    email: userProfile.email,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    organization: userProfile.organization,
    areaOfLaw: userProfile.areaOfLaw,
    topicsOfInterest: userProfile.topicsOfInterest
  })
  const [message, setMessage] = useState('')
  const [info, setInfo] = useState('')
  const [initials, setInitials] = useState(defineNames(userProfile.username, userProfile.firstName, userProfile.lastName))
  const [name, setName] = useState(userProfile.firstName.length > 0 ? userProfile.firstName : userProfile.username)
  const [isEdited, setIsEdited] = useState(Boolean)
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [isAllSubjects, setAllSubjects] = useState(false)

  const handleTopicChange = (selectedTopic: string): void => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(selectedTopic)) {
        setValues((prevValues) => ({
          ...prevValues,
          topicsOfInterest: prevValues.topicsOfInterest.filter(
            (topic) => topic !== selectedTopic
          )
        }))
        return prevSelectedTopics.filter((topic) => topic !== selectedTopic)
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          topicsOfInterest: [...prevValues.topicsOfInterest, selectedTopic]
        }))
        return [...prevSelectedTopics, selectedTopic]
      }
    })
    setIsEdited(true)
    setMessage('')
  }

  const handleToggleAllSubjects = (): void => {
    if (isAllSubjects) {
      handleDeselectAll()
    } else {
      const allSubjects = topics
      setSelectedTopics(allSubjects)
      setValues((prevValues) => ({
        ...prevValues,
        topicsOfInterest: topics
      }))
    }
    setAllSubjects(!isAllSubjects)
    setMessage('')
    setIsEdited(true)
  }

  const handleDeselectAll = (): void => {
    setSelectedTopics([])
    setValues((prevValues) => ({
      ...prevValues,
      topicsOfInterest: []
    }))
  }

  const handleChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, parameter: string) => {
      const { value } = e.target
      setValues((prevValues) => ({
        ...prevValues,
        [parameter]: value
      }))
      setMessage('')
      setIsEdited(true)
    },
    [setValues, setMessage, setIsEdited]
  )

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    setInfo('')
    setMessage('')
    setValues({
      username: userProfile.username,
      email: userProfile.email,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      areaOfLaw: userProfile.areaOfLaw,
      organization: userProfile.organization,
      topicsOfInterest: userProfile.topicsOfInterest
    })
    setIsEdited(false)
  }

  const handleProfileSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ): void => {
    event.preventDefault()
    setInfo('')
    setMessage('')
    const updatedValues = { ...values }
    if (updatedValues.firstName === '' || updatedValues.lastName === '') {
      setMessage('error')
      setIsEdited(false)
      return
    }

    updateUserProfile(updatedValues)
      .then((res) => {
        if (res.data.Status === 'Success') {
          setMessage('success')
          setIsEdited(false)
          setInitials(
            defineNames(userProfile.username, updatedValues.firstName, updatedValues.lastName)
          )
          setName(updatedValues.firstName)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    // Initialize selectedTopics with topicsOfInterest from userProfile
    if (values.topicsOfInterest.length === topics.length) {
      setAllSubjects(true)
    }
    setSelectedTopics(userProfile.topicsOfInterest)
  }, [userProfile])

  return (
    <ProfileView
      profileValues={values}
      displayMessage={message}
      handleProfileChange={handleChanges}
      info={info}
      handleSubmit={handleProfileSubmit}
      isEdited={isEdited}
      userInitials={initials}
      handleCancel={handleCancel}
      handleTopicChange={handleTopicChange}
      selectedTopics={selectedTopics}
      handleToggle={handleToggleAllSubjects}
      isAllSubjects={isAllSubjects}
      displayName={name}
    />
  )
}

export default ProfileController
