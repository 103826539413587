import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './passwordRequirements.css'

const PasswordRequirements: React.FC = () => {
  return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={
                <Popover id="password-popover" className='pwd-popover'>
                    <Popover.Header as="h3">Password Requirements</Popover.Header>
                    <Popover.Body>
                        <ul>
                            <li>At least 8 characters long</li>
                            <li>At least one number</li>
                            <li>At least one capital letter</li>
                            <li>At least one lower case letter</li>
                        </ul>
                    </Popover.Body>
                </Popover>
            }
        >
            <button type='button' className='pwd-button' tabIndex={-1}>
                <FontAwesomeIcon className='pwd-icon' icon={faInfoCircle} />
            </button>
        </OverlayTrigger>
  )
}

export default PasswordRequirements
