import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import ConversationBtn from '../../../../assests/images/ConversationBtn.png'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'
import { CANDOO_SEARCH, LOGIN_URL, MEMO, CASE_CHAT_GIF } from '../../documentConstants'

export default function CaseChat (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div>
        <div className="memo-container">
          <p className="memo-text">
            CanDoo&rsquo;s case-chat enhances your legal research by providing
            you with instant answers for specific questions about cases. Quickly
            obtain key information like the judge, verdict, quantums, and more,
            while bypassing the need to read the entire case. Ideal for lawyers,
            paralegals, and law students needing swift access to relevant case
            details.
          </p>

          <h2 className="overview-heading">Features</h2>
          <ul className="memo-text">
            <li>
              <strong>Instant Case Information:</strong> Quickly get answers to
              specific questions about a case.
            </li>
            <li>
              <strong>Key Information Extraction:</strong> Provides details such
              as the judge, verdict, and important case elements.
            </li>
            <li>
              <strong>Reference Support:</strong> Includes references to the
              specific parts of the case where the information was derived.
            </li>
          </ul>
          <h2 className="overview-heading">How to Use the Case Chat Feature</h2>
        </div>
        <div className="row organize-video-row">
          <div className="col-auto memo-video">
            <img
              src={CASE_CHAT_GIF}
              className="video-play"
              alt="Case Chat Overview"
            />
          </div>
          <div className="col-auto memo-steps memo-video memo-container">
            <ul>
              <h5 className="overview-sub-heading">
                Step 1: Ensure You Are{' '}
                <a
                  className="clicks"
                  href={LOGIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Logged In
                </a>
              </h5>
              <ul className="overview-text">
                <li>
                  Make sure you are{' '}
                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    logged in
                  </a>{' '}
                  to your CanDoo account.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 2: Search and navigate to a case
              </h5>
              <ul className="overview-text">
                <li>
                  Use CanLII&rsquo;s search page to navigate to a desired case.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 3: Open the Conversation Panel
              </h5>
              <ul className="overview-text">
                <li>
                  Click on the conversation button{' '}
                  <img src={ConversationBtn} alt="Conversation Button" /> at the
                  top right corner to open the side panel.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 4: Select the Case Chat Tab
              </h5>
              <ul className="overview-text">
                <li>
                  In the side panel, select the &quot;Case Chat&quot; tab to
                  start a new conversation.
                </li>
              </ul>

              <h5 className="overview-sub-heading">Step 5: Ask Questions</h5>
              <ul className="overview-text">
                <li>
                  You are now ready to chat with the case. Start by asking
                  questions like &quot;Who is the judge?&quot; or any other
                  specific queries you have about the case.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <DocumentNavigator prev={MEMO} next={CANDOO_SEARCH} />
      </div>
    </Box>
  )
}
