import {
  MEMO,
  CASE_CHAT,
  CANDOO_SEARCH,
  CASE_CHAT_HISTORY,
  PDF_HISTORY,
  PDF_PROJECT,
  PDF_BULK,
  INSTALL_EXTENSION,
  FAQ
} from './documentConstants'
export const renderContent = (pageText: string): string => {
  const defaultRoute: string = '/document'
  switch (pageText) {
    case MEMO:
      return `${defaultRoute}/case-memo`
    case CASE_CHAT:
      return `${defaultRoute}/case-chat`
    case CANDOO_SEARCH:
      return `${defaultRoute}/candoo-search`
    case CASE_CHAT_HISTORY:
      return `${defaultRoute}/search-history`
    case PDF_PROJECT:
      return `${defaultRoute}/pdf-project`
    case PDF_HISTORY:
      return `${defaultRoute}/pdf-history`
    case PDF_BULK:
      return `${defaultRoute}/bulk-question`
    case INSTALL_EXTENSION:
      return `${defaultRoute}/install-extension`
    case FAQ:
      return `${defaultRoute}/faq`
    default:
      return `${defaultRoute}`
  }
}
