export const SECONDARY_URL: string = 'https://penguin-rike4beueq-uc.a.run.app'
export const PRIMARY_URL: string = 'https://app.candoo.ai'
export const LOCAL_HOST_PRIMARY: string = 'http://localhost:3000'
export const LOCAL_HOST_SECONDARY: string = 'http://localhost:3001'
export const LOCAL_HOST_PDF_CHAT_DEV: string = 'http://localhost:5173'
export const STAGING_SECONDARY_URL: string =
  'https://penguin-staging-rike4beueq-uc.a.run.app'
export const STAGING_PRIMARY_URL: string = 'https://app.staging.candoo.ai'
export const PDF_CHAT_STAGING: string = 'https://pdf.staging.candoo.ai'
export const PDF_CHAT_PROD: string = 'https://pdf.candoo.ai'
export const CANDOO_ADV_SITE: string = 'https://candoo.ai/'
export const CANLII_SITE: string = 'https://www.canlii.org/'
export const CANDOO_LINKEDIN: string = 'https://www.linkedin.com/company/candoo-ai/'
export const CANLII_IDENTIFIER: string = 'canlii-auth'
export const REGISTRATION_COMPLETE: string = 'registered'
export const TERMS: string = 'https://www.formic.legal/candooterms.html'
export const DOCUMENTATION: string = 'https://app.candoo.ai/document'

export type Admins = Record<number, string>
export const admins = ['benji@formic.ai', 'varun@formic.ai', 'deepu@formic.ai']
