import * as React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useAction } from '../../../contexts/useAction'
import { useNavigate } from 'react-router-dom'
import { renderContent } from '../DocumentRouter'
interface DocumentNavigatorProps {
  prev: string
  next: string
}

export function DocumentNavigator ({
  prev,
  next
}: DocumentNavigatorProps): JSX.Element {
  const action = useAction()
  const navigate = useNavigate()

  const handleItemClick = (item: string): void => {
    action.setActiveTitle(item)
    navigate(renderContent(item))
  }
  return (
    <div className="documentation-navigator">
      <button
        className="btn next-btn"
        onClick={() => {
          handleItemClick(prev)
        }}
      >
        <NavigateBeforeIcon /> {prev}
      </button>
      <button
        className="btn next-btn"
        onClick={() => {
          handleItemClick(next)
        }}
      >
        {next} <NavigateNextIcon />
      </button>
    </div>
  )
}
