import React from 'react'
import { type UserPreferencesProps, topics } from '../ProfileInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'

export const UserPreferences: React.FC<UserPreferencesProps> = ({
  handleTopicChange,
  selectedTopics,
  handleToggle,
  isAllSubjects
}) => {
  const chunkArray = (array: string[], chunkSize: any): string[][] => {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }
    return chunks
  }

  const chunkedTopics = chunkArray(topics, 11)

  return (
    <div>
      <h4 className="heading-style">User Preferences</h4>
      <div className="mb-4 user-preferences box-style">
        <div className="row profile-label">
          <div className="profile-style-toi">
            <label htmlFor="topicsOfInterest">
              <strong>Topics of Interest</strong>
            </label>
          </div>
        </div>
        <div className="col-15">
          <div className="all-subjects">
            <button type="button" className="toggle" onClick={handleToggle}>
              <FontAwesomeIcon
                className="toggle-icon"
                icon={isAllSubjects ? faToggleOn : faToggleOff}
              />
            </button>
            <span
              className={`all-subjects-text ${
                isAllSubjects ? 'bold-text' : ''
              }`}
            >
              All Subjects
            </span>
          </div>
          <div className="row">
            {chunkedTopics.map((column, columnIndex) => (
              <div key={columnIndex} className="col-3">
                {column.map((topic: string, rowIndex: number) => (
                  <div key={rowIndex} className="topics">
                    <div className="row">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          value={topic}
                          checked={selectedTopics.includes(topic)}
                          onChange={() => {
                            handleTopicChange(topic)
                          }}
                        />
                      </div>
                      <div
                        className={`col-10 ${
                          selectedTopics.includes(topic) ? 'checkbox-text' : ''
                        }`}
                      >
                        <span>{topic}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <br className='margin-blank'/>
      </div>
    </div>
  )
}
