export const validatePassword = (password: string): boolean => {
  // Minimum length of 8 characters
  if (password.length < 8) {
    return false
  }

  // At least one number
  if (!/\d/.test(password)) {
    return false
  }

  // At least one capital letter
  if (!/[A-Z]/.test(password)) {
    return false
  }

  // At least one lower case letter
  if (!/[a-z]/.test(password)) {
    return false
  }

  // All conditions met
  return true
}

export const validateName = (name: string): boolean => {
  const allowedSymbolsPattern = /^[a-zA-Z0-9.-\s]+$/
  const startsWithNumberPattern = /^[0-9]/

  if (name.length < 1 || name.length > 15) {
    return false
  }

  if (!allowedSymbolsPattern.test(name)) {
    return false
  }

  if (name.includes(' ')) {
    return false
  }

  if (startsWithNumberPattern.test(name.charAt(0))) {
    return false
  }

  return true
}
