import React, { useState, useEffect } from 'react'
import { resetPassword, type Credentials } from '../../api'
import PasswordRequirements from '../Reusable/passwordRequirements/passwordRequirements'
import { useLocation, useNavigate } from 'react-router-dom'
import { validatePassword } from '../../utils/validation'
import ShowPassword from '../Reusable/ShowPassword/ShowPassword'

export interface PasswordResetInterface extends Credentials {
  secret_code: string
}

const ResetPassword: React.FC = () => {
  const [info, setInfo] = useState('')
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValidation, setPasswordValidation] = useState('')
  const [passwordType, setPasswordType] = useState('password')
  const [values, setValues] = useState<PasswordResetInterface>({
    email: '',
    password: '',
    secret_code: ''
  })
  const location = useLocation()

  function comparePasswords (
    newPassword: string,
    confirmPassword: string
  ): boolean {
    return newPassword === confirmPassword
  }

  const handlePasswordTypeChange = (): void => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setInfo('')

    if (!comparePasswords(values.password, confirmPassword)) {
      setInfo('no-match')
      return
    }

    const isPasswordValid = validatePassword(values.password)

    if (!isPasswordValid) {
      setPasswordValidation('Password Requirements are not met')
      return
    } else {
      setPasswordValidation('')
    }

    resetPassword(values)
      .then((res) => {
        if (res.data.Status === 'Success') {
          navigate(`/login?email=${values.email}`)
          setInfo('success')
        } else {
          alert(res.data.Error)
        }
      })
      .catch((err) => {
        const response = err.response
        if (response?.status === 401) {
          setInfo('invalid')
        } else if (
          response?.status === 404 ||
          response.data?.Error !== undefined ||
          response.data?.Error !== null
        ) {
          setInfo('failure')
        } else {
          setInfo('unknown')
        }
        console.error(err)
      })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const emailParam = searchParams.get('email')
    if (emailParam !== null) {
      setValues((prevValues) => ({
        ...prevValues,
        email: emailParam
      }))
      setEmail(emailParam)
      setInfo('cached')
    }
  }, [location.search])

  return (
    <div>
      <div className="error-messages-container">
        <h2 className="sub-header text-center">Reset Password</h2>
        {info === 'success' && (
          <h4 className="success-message text-center">
            Your Password has been reset successfully
          </h4>
        )}
        {info === 'failure' && (
          <h4 className="fail-message text-center">
            User not found. Please try again
          </h4>
        )}
        {info === 'no-match' && (
          <h4 className="fail-message text-center">Passwords not matched</h4>
        )}
        {info === 'unknown' && (
          <h4 className="fail-message text-center">
            Password Reset failed. Please try again
          </h4>
        )}
        {info === 'invalid' && (
          <h4 className="fail-message text-center">
            Invalid Email or Secret Code Provided
          </h4>
        )}
        {info === 'cached' && (
          <h4 className="success-message text-center">
            We have sent an email with a security code to {email} to reset your
            password.
          </h4>
        )}
        {passwordValidation !== '' && (
          <h4 className="fail-message text-center">{passwordValidation}</h4>
        )}
      </div>
      <p className="help-text">
        Tip💡: Please ensure to adhere our password requirements
      </p>
      <div className="container-ratio justify-content-center">
        <div className="margin-base login-container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="bg-white p-4 rounded-9">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your registered Email"
                      name="email"
                      value={values.email}
                      className="form-control rounded-0"
                      onChange={(e) => {
                        setValues({ ...values, email: e.target.value })
                      }}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="new_password">
                      <strong>
                        New Password <PasswordRequirements />
                      </strong>
                    </label>
                    <input
                      type={passwordType}
                      name="new_password"
                      className="form-control rounded-0"
                      value={values.password}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          password: e.target.value
                        })
                      }}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="confirm_password">
                      <strong>Confirm Password</strong>
                    </label>
                    <input
                      type={passwordType}
                      name="confirm_password"
                      className="form-control rounded-0"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }}
                      required
                    />
                    <div>
                      <ShowPassword
                        onPasswordTypeChange={handlePasswordTypeChange}
                        passwordType={passwordType}
                        displayText='Show both Passwords'
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="security_code">
                      <strong>Security Code</strong>
                    </label>
                    <input
                      type="text"
                      name="security_code"
                      className="form-control rounded-0"
                      value={values.secret_code}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          secret_code: e.target.value
                        })
                      }}
                      placeholder="Enter security code sent over email"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark w-100 rounded-9 btn-color-main"
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword
