import React, { useEffect, useState } from 'react'
import { getUserData, logoutUser } from '../../api'
import { defineNames } from '../../utils/helpers'
import { Session } from './Session/Session'
import { Inactive } from '../Reusable/Inactive/Inactive'
import './Home.css'
import FooterHome from '../Reusable/FooterHome/FooterHome'
import { NavBar } from '../Reusable/NavigationBar/NavBar'
import { useAction } from '../../contexts/useAction'
import NewsFeed from '../Reusable/NewsFeed/NewsFeed'

export default function Home (): JSX.Element {
  const [name, setName] = useState('')
  const [initials, setInitials] = useState('')
  const action = useAction()

  useEffect(() => {
    getUserData()
      .then((res) => {
        if (res.data.Status === 'Success') {
          const userDetails = res.data.userProfile
          action.setLoginStatus(true)
          action.setProfile(userDetails)
          extractNames(userDetails)
        } else {
          action.setLoginStatus(false)
        }
      })
      .catch((err) => {
        console.error('Failed to fetch user details: ', err)
      })
  }, [])

  const extractNames = (userDetails: any): void => {
    setName(
      userDetails.firstName.length > 0
        ? userDetails.firstName
        : userDetails.name
    )
    setInitials(
      defineNames(userDetails.name, userDetails.firstName, userDetails.lastName)
    )
  }

  const handleLogout = (): void => {
    logoutUser()
      .then((res) => {
        if (res.data.Status === 'Success') {
          window.location.reload()
        } else {
          console.error('Logout Error')
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div>
      <NavBar />
      <div className="container margin-base">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="justify-content-center mb-5">
              <div className="container justify-content-center">
                <div className="row justify-content-center">
                  {action.isLoggedIn
                    ? (
                    <>
                      <Session
                        initials={initials}
                        name={name}
                        handleLogout={handleLogout}
                      />
                    </>
                      )
                    : (
                    <Inactive />
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsFeed />
      <div style={{ marginTop: '10em' }}>
        <FooterHome />
      </div>
    </div>
  )
}
