import InstallDesktopIcon from '@mui/icons-material/InstallDesktop'
import ChatIcon from '@mui/icons-material/Chat'
import DescriptionIcon from '@mui/icons-material/Description'
import PageviewIcon from '@mui/icons-material/Pageview'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import HistoryIcon from '@mui/icons-material/History'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'

export const CASE_CHAT: string = 'Case Chat'
export const MEMO: string = 'Memo'
export const CANDOO_SEARCH: string = 'CanDoo Search'
export const CASE_CHAT_HISTORY: string = 'Case Chat History'
export const PDF_PROJECT = 'PDF Project'
export const PDF_HISTORY = 'Project History'
export const PDF_BULK = 'Bulk Question'
export const OVERVIEW = 'Overview'
export const PDF_CHAT_FEATURES: string[] = [
  PDF_PROJECT,
  PDF_HISTORY,
  PDF_BULK
]
export const FAQ = 'Frequently Asked Questions'
export const INSTALL_EXTENSION = 'Install CanDoo Extension'
export const EXTENSION_FEATURES: string[] = [
  MEMO,
  CASE_CHAT,
  CANDOO_SEARCH,
  CASE_CHAT_HISTORY
]
export const CANLII_URL = 'https://www.canlii.org/en/'
export const CHROME_STORE_URL = 'https://chromewebstore.google.com/detail/candoo-find-better-inform/jbhiloilkgcbddifgjconnejjhjpllai'
export const LOGIN_URL = 'https://app.candoo.ai/login?ref=canlii-auth'
export const CASE_CHAT_URL = 'https://app.candoo.ai/document/case-chat'
export const CASE_MEMO_URL = 'https://app.candoo.ai/document/case-memo'

// gif urls
export const CASE_CHAT_GIF = 'https://storage.googleapis.com/version-updates/cchat.gif'
export const CANDOO_SEARCH_GIF = 'https://storage.googleapis.com/documentation-videos/candoosearch.gif'
export const MEMO_GIF = 'https://storage.googleapis.com/documentation-videos/memo.gif'
export const INSTALL_GIF = 'https://storage.googleapis.com/documentation-videos/install.gif'
export const CASE_CHAT_HISTORY_GIF = 'https://storage.googleapis.com/documentation-videos/case%20chat%20history.gif'
export const LOGIN_GIF = 'https://storage.googleapis.com/documentation-videos/loginSnap.gif'

export interface Feature {
  heading: string
  description: string
  icon: React.ElementType
  value: string
}

export const extensionFeaturesTest: Feature[] = [
  {
    heading: 'Install',
    description: 'Get started with installing the CanDoo AI extension.',
    icon: InstallDesktopIcon,
    value: INSTALL_EXTENSION
  },
  {
    heading: 'Case Chat',
    description: 'Chat with CanLii Cases rather than reading it!',
    icon: ChatIcon,
    value: CASE_CHAT
  },
  {
    heading: 'Memo',
    description: 'Get key case insights in just 2 minutes with memos!',
    icon: DescriptionIcon,
    value: MEMO
  },
  {
    heading: 'CanDoo Search',
    description:
      'Discover cases quicker, enhance research quality for superior outcomes.',
    icon: PageviewIcon,
    value: CANDOO_SEARCH
  }
]

export const pdfFeatures: Feature[] = [
  {
    heading: 'Case Chat History',
    description: 'Revive your existing Case Chats.',
    icon: HistoryIcon,
    value: CASE_CHAT_HISTORY
  },
  {
    heading: 'Pdf Project',
    description: 'Start your research with your CanDooPDF',
    icon: PictureAsPdfIcon,
    value: PDF_PROJECT
  },
  {
    heading: 'Bulk Question',
    description: 'Save time with one question for all project PDFs!',
    icon: DynamicFeedIcon,
    value: PDF_BULK
  },
  {
    heading: 'Pdf Project History',
    description:
      'Revive your existing Pdf Project',
    icon: WorkHistoryIcon,
    value: PDF_HISTORY
  }
]
