import React from 'react'
import { type ProfileMessagesProps } from '../ProfileInterface'

export const ProfileMessages: React.FC<ProfileMessagesProps> = ({
  displayName,
  displayMessage,
  info,
  isEdited
}) => {
  return (
    <div className="row justify-content-center text-center">
      <h4
        className="sub-header text-center mb-3 mt-3"
        style={{ maxWidth: '100%' }}
      >
        Manage your info to make Candoo work better for you
      </h4>
      <div className="w-50">
        {displayMessage === 'success' && (
          <h4 className="success-message text-center">Changes Saved</h4>
        )}
        {displayMessage === 'error' && (
          <h4 className="fail-message text-center">
            Invalid Input: First Name or Last Name can&apos;t be empty
          </h4>
        )}
        {isEdited && (
          <h4 className="unstaged-message text-center">
            You have unsaved changes
          </h4>
        )}
        {info.length > 1 && (
          <h4 className="fail-message text-center">{info}</h4>
        )}
      </div>
    </div>
  )
}
