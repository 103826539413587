import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import CanDooSearchHistory from '../../../../assests/images/SearchHistory.png'
import CanDooSearchBtn from '../../../../assests/images/AdvancedSearch.png'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'
import { CASE_CHAT_HISTORY, CASE_CHAT, LOGIN_URL, CANDOO_SEARCH_GIF } from '../../documentConstants'

export default function CanDooSearch (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div>
        <div className="memo-container">
          <p className="memo-text">
            CanDoo Search revolutionizes legal research by streamlining CanLII
            case search queries, delivering precise results tailored to your
            needs. Whether you’re investigating &rdquo;car theft&rdquo; or
            delving into &rdquo;intellectual property&rdquo; or any other CanLII
            case keywords, our advanced algorithm enhances search efficiency,
            providing unparalleled precision and speed. This feature is an
            indispensable asset for legal professionals and students alike,
            facilitating thorough and efficient research processes.
          </p>

          <h2 className="overview-heading">Features</h2>
          <ul className="memo-text">
            <li>
              <strong>Optimized Search Queries:</strong> Automatically refines
              your search terms for better results.
            </li>
            <li>
              <strong>Enhanced Search Results:</strong> Provides the best
              possible matches based on your query.
            </li>
            <li>
              <strong>Search History:</strong> Review and revisit your past
              searches easily.
            </li>
          </ul>
          <h2 className="overview-heading">
            How to Use the CanDoo Search Feature
          </h2>
        </div>
        <div className="row organize-video-row">
          <div className="col-auto memo-video">
            <img
              src={CANDOO_SEARCH_GIF}
              className="video-play"
              alt="CanDoo Search Overview"
            />
          </div>
          <div className="col-auto memo-steps memo-video memo-container">
            <ul>
            <h5 className="overview-sub-heading">
                Step 1: Ensure You Are{' '}
                <a
                  className="clicks"
                  href={LOGIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Logged In
                </a>
              </h5>
              <ul className="overview-text">
                <li>
                  Make sure you are{' '}
                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    logged in
                  </a>{' '}
                  to your CanDoo account.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 2: Enter Your Search Query
              </h5>
              <ul className="overview-text">
                <li>
                  <strong>Type Query:</strong> Enter any search query, such as
                  &apos;intellectual property theft&apos;, into the document
                  text box.
                </li>
                <li>
                  <strong>CanDoo Optimization:</strong> Click the CanDoo Search
                  button{' '}
                  <img
                    src={CanDooSearchBtn}
                    alt="CanDoo Search History Button"
                  />
                  . Your query will be optimized to include broader terms, such
                  as &apos;(theft OR larceny OR thievery OR thieving OR
                  stealing) &#34;intellectual property&#34;&apos;, along with
                  other relevant case filters.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 3: Browse Through the Search Results
              </h5>
              <ul className="overview-text">
                <li>
                  <strong>Review Results:</strong> Browse through the optimized
                  search results to find the best matching cases.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 4: Review Your Search History
              </h5>
              <ul className="overview-text">
                <li>
                  <strong>Open History:</strong> Click on the history button{' '}
                  <img
                    src={CanDooSearchHistory}
                    alt="CanDoo Search History Button"
                  />{' '}
                  on the CanLII homepage. This will open the side panel where
                  you can review your previous search queries.
                </li>
                <li>
                  <strong>Access Past Searches:</strong> Select any past search
                  from the history list to revisit and refine your research.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <DocumentNavigator prev={CASE_CHAT} next={CASE_CHAT_HISTORY} />
      </div>
    </Box>
  )
}
