import firebase from 'firebase/app'
import 'firebase/auth'
import { fetchFirebaseDetails } from '../api'
import {
  MICROSOFT,
  firebaseClashErrorCode,
  firebasePopUpError,
  microsoftProviderUrl,
  ssoAccountExists,
  ssoPopupError
} from './messageConstants'

let firebaseDetails: any
export let auth: any,
  googleProvider: any,
  emailAuthProvider: any,
  microSoftProvider: any
try {
  firebaseDetails = await fetchFirebaseDetails()
  const firebaseConfig: any = firebaseDetails.data.Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  auth = firebaseApp.auth()
  googleProvider = new firebase.auth.GoogleAuthProvider()
  emailAuthProvider = firebase.auth.EmailAuthProvider
  microSoftProvider = new firebase.auth.OAuthProvider(microsoftProviderUrl)
} catch (error: any) {
  console.error('Failed to fetch Firebase configuration')
}

export interface SsoProfile {
  email?: string
  name?: string
}

export async function processLogin (
  provider: string
): Promise<SsoProfile | undefined | string> {
  try {
    let result
    if (provider === MICROSOFT) {
      result = await auth.signInWithPopup(microSoftProvider)
    } else {
      result = await auth.signInWithPopup(googleProvider)
    }

    const profile: any = result?.additionalUserInfo?.profile
    let email, name
    if (provider === MICROSOFT) {
      email = profile?.mail ?? ''
      name = profile?.displayName ?? ''
    } else {
      email = profile?.email ?? ''
      name = profile?.name ?? ''
    }
    return { email, name }
  } catch (error) {
    console.error('Error during login process:', error)
    return handleFirebaseErrors(error)
  }
}

function handleFirebaseErrors (error: any): string | undefined {
  console.log('ERROR CODE IS: ', error.code)

  if (error.code === firebasePopUpError) {
    return ssoPopupError
  } else if (error.code === firebaseClashErrorCode) {
    return ssoAccountExists
  }
  return undefined
}
