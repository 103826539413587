import React, { useState, useCallback } from 'react'
import {
  RegistrationStatus,
  type FormValues,
  type RegisterControllerProps
} from '../RegisterInterface'
import RegisterFormView from '../View/RegisterFormView'
import { registerUser } from '../../../api'
import { validatePassword } from '../../../utils/validation'
import { type SsoProfile, processLogin } from '../../../utils/Firebase'
import { useNavigate } from 'react-router-dom'
import {
  passwordRequirementsError,
  ssoAccountExists,
  ssoPopupError
} from '../../../utils/messageConstants'
import { CANLII_SITE } from '../../../utils/constants'

const RegisterFormController: React.FC<RegisterControllerProps> = ({
  registrationStatus,
  setRegistrationStatus,
  givenEmail,
  setGivenEmail,
  validations,
  setValidations
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    password: '',
    organization: '',
    role: 'lawyer',
    firstName: '',
    lastName: '',
    areaOfLaw: ''
  })

  const [checked, setChecked] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  const navigate = useNavigate()

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setChecked(event.target.checked)
    if (!checked) {
      setValidations({ ...validations, terms: '' })
    }
  }

  const handlePasswordTypeChange = (): void => {
    setPasswordType((prevType) =>
      prevType === 'password' ? 'text' : 'password'
    )
  }

  const handleChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, parameter: string) => {
      const { value } = e.target
      setFormValues({
        ...formValues,
        [parameter]: value
      })
    },
    [formValues]
  )

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentPassword = e.target.value
    if (validations.password !== '') {
      if (validatePassword(currentPassword)) {
        setValidations((prevValidations) => ({
          ...prevValidations,
          password: ''
        }))
      }
    }
    setFormValues({
      ...formValues,
      password: currentPassword
    })
  }

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedRole = e.target.value

    setFormValues({
      ...formValues,
      role: selectedRole
    })
  }

  const resetForm = useCallback(() => {
    setFormValues({
      name: '',
      email: '',
      password: '',
      organization: '',
      role: '',
      firstName: '',
      lastName: '',
      areaOfLaw: ''
    })

    setValidations({
      password: '',
      terms: ''
    })
  }, [])

  function handleSSOErrors (ssoError: undefined | string): void {
    if (ssoError === undefined) {
      setRegistrationStatus(RegistrationStatus.SsoError)
    } else if (ssoError === ssoAccountExists) {
      setRegistrationStatus(RegistrationStatus.SsoAccountExists)
    } else if (ssoError === ssoPopupError) {
      setRegistrationStatus(RegistrationStatus.SsoPopupError)
    }
  }

  const handleSSORegistration = async (provider: string): Promise<void> => {
    try {
      const ssoProfile: SsoProfile | undefined | string = await processLogin(
        provider
      )
      if (ssoProfile === undefined || typeof ssoProfile === 'string') {
        handleSSOErrors(ssoProfile)
        return
      }
      let email, name
      if (typeof ssoProfile !== 'string') {
        email = ssoProfile.email
        name = ssoProfile.name
      }
      if (typeof ssoProfile !== 'string' && name != null && email != null) {
        const trimmedName: string = name.replace(/\s+/g, '').slice(0, 15)
        handleSSOResponse(email, trimmedName)
      }
    } catch (error) {
      console.error('SSO ERROR: ', error)
    }
  }

  const handleSSOResponse = (ssoEmail: string, ssoUserName: string): void => {
    navigate(`/sso/register?email=${ssoEmail}&username=${ssoUserName.trim()}`)
  }

  function performRegistrationValidations (): boolean {
    setGivenEmail('')

    if (!checked) {
      setValidations({ ...validations, terms: 'idle' })
      return false
    } else {
      setValidations({ ...validations, terms: '' })
    }

    const isPasswordValid = validatePassword(formValues.password)
    if (!isPasswordValid) {
      setValidations((prevValidations) => ({
        ...prevValidations,
        password: passwordRequirementsError
      }))
      return false
    } else {
      setValidations((prevValidations) => ({
        ...prevValidations,
        password: ''
      }))
    }

    return true
  }

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault()

      setRegistrationStatus(RegistrationStatus.Loading)

      if (!performRegistrationValidations()) {
        setRegistrationStatus(RegistrationStatus.Idle)
        return
      }

      registerUser(formValues)
        .then((res) => {
          if (res.data.Status === 'Success') {
            setRegistrationStatus(RegistrationStatus.Idle)
            resetForm()
            window.location.href = CANLII_SITE
          } else {
            setRegistrationStatus(RegistrationStatus.Error)
          }
        })
        .catch((err) => {
          const errorRegistrationStatus = handleErrors(err)
          setRegistrationStatus(errorRegistrationStatus)
        })
    },
    [checked, formValues, resetForm, validations]
  )

  function handleErrors (err: any): RegistrationStatus {
    let registrationStatus = RegistrationStatus.Idle

    if (err.response !== undefined) {
      if (err.response.status === 409) {
        const errorMessage = err.response.data.Error

        if (errorMessage === 'emailExists') {
          registrationStatus = RegistrationStatus.EmailExists
        }

        if (errorMessage === 'usernameExists') {
          registrationStatus = RegistrationStatus.UsernameExists
        }
      } else if (err.response.status === 500 || err.response.status === 400) {
        registrationStatus = RegistrationStatus.Error
      } else {
        console.error(err)
      }
    } else {
      console.error(err)
    }

    return registrationStatus
  }

  return (
    <RegisterFormView
      formValues={formValues}
      handleChanges={handleChanges}
      handlePasswordChange={handlePasswordChange}
      handlePasswordTypeChange={handlePasswordTypeChange}
      handleCheckboxChange={handleCheckboxChange}
      handleRoleChange={handleRoleChange}
      handleSubmit={handleSubmit}
      checked={checked}
      passwordType={passwordType}
      givenEmail={givenEmail}
      registrationStatus={registrationStatus}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      handleSsoRegistration={handleSSORegistration}
    />
  )
}

export default RegisterFormController
