import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import CanDooSearchHistory from '../../../../assests/images/SearchHistory.png'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'
import { FAQ, CANDOO_SEARCH, CASE_CHAT_URL, LOGIN_URL, CASE_CHAT_HISTORY_GIF } from '../../documentConstants'

export default function CaseChatHistory (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div>
        <div className="memo-container">
          <p className="memo-text">
            Accessing your case chat history enhances your legal research by
            allowing you to seamlessly continue asking questions about
            previously reviewed cases. Easily manage and build upon your
            research, obtaining key information without starting from scratch.
          </p>

          <h2 className="overview-heading">Features</h2>
          <ul className="memo-text">
            <li>
              <strong>Store Case Chats:</strong> Keep a history of all your case
              chats for easy access.
            </li>
            <li>
              <strong>Revisit and Continue:</strong> Open previous case chats
              and continue your inquiries seamlessly.
            </li>
            <li>
              <strong>Organized Access:</strong> Easily navigate through your
              chat history to find specific case discussions.
            </li>
          </ul>
          <h2 className="overview-heading">
            How to Use the Case Chat History Feature
          </h2>
        </div>
        <div className="row organize-video-row">
          <div className="col-auto memo-video">
            <img
              src={CASE_CHAT_HISTORY_GIF}
              className="video-play"
              alt="Case Chat History Overview"
            />
          </div>
          <div className="col-auto memo-steps memo-video memo-container">
            <ul>
              <h5 className="overview-sub-heading">
                Step 1: Ensure You Are{' '}
                <a
                  className="clicks"
                  href={LOGIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Logged in
                </a>
              </h5>
              <ul className="overview-text">
                <li>
                  Ensure you are{' '}
                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    logged in
                  </a>{' '}
                  to your account on the CanDoo AI plugin.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 2: Access Case Chat History
              </h5>
              <ul className="overview-text">
                <li>
                  Click on the history button{' '}
                  <img
                    src={CanDooSearchHistory}
                    alt="CanDoo Search History Button"
                  />{' '}
                  on the homepage of CanLII. This will open the side panel.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 3: Review Your Previous{' '}
                <a
                  className="clicks"
                  href={CASE_CHAT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Case Chat
                </a>
                s
              </h5>
              <ul className="overview-text">
                <li>
                  In the side panel, click on the &quot;History&quot; tab to
                  view your previous case chats.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 4: Select and Open a{' '}
                <a
                  className="clicks"
                  href={CASE_CHAT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Case Chat
                </a>
              </h5>
              <ul className="overview-text">
                <li>
                  From your history list, select the desired{' '}
                  <a
                    className="clicks"
                    href={CASE_CHAT_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    case chat
                  </a>
                  . Clicking on it will open the chat in a new tab.
                </li>
              </ul>

              <h5 className="overview-sub-heading">
                Step 5: Continue Your Conversation
              </h5>
              <ul className="overview-text">
                <li>
                  You can now continue asking questions in the reopened case
                  chat. Start with inquiries like &quot;Who is the judge?&quot;
                  or any other specific questions about the case.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <DocumentNavigator prev={CANDOO_SEARCH} next={FAQ} />
      </div>
    </Box>
  )
}
