import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import ChromeBrowserIcon from '../../../../assests/images/chrome.svg'
import MicrosoftBrowserIcon from '../../../../assests/images/microsoft-edge.svg'
import BraveBrowserIcon from '../../../../assests/images/brave-browser.svg'
import CanDooSearch from '../../../../assests/images/AdvancedSearch.png'
import CanDooSearchHistory from '../../../../assests/images/SearchHistory.png'
import ConversationBtn from '../../../../assests/images/ConversationBtn.png'
import { OVERVIEW, MEMO, CANLII_URL, CHROME_STORE_URL, LOGIN_URL, CASE_CHAT_URL, CASE_MEMO_URL, INSTALL_GIF, LOGIN_GIF } from '../../documentConstants'
import AddToChrome from '../../../../assests/images/file.png'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'

export default function InstallExtension (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div className="install-container">
        <p>
          Enhance your browsing experience with the CanDoo extension, available
          for{' '}
          <img
            src={ChromeBrowserIcon}
            alt="Chrome Icon"
            className="browser-icon"
          />
          ,{' '}
          <img
            src={MicrosoftBrowserIcon}
            alt="Microsoft Edge Icon"
            className="browser-icon"
          />
          ,{' '}
          <img
            src={BraveBrowserIcon}
            alt="Brave Icon"
            className="browser-icon"
          />{' '}
          and more. For the best experience, we recommend using this extension
          in{' '}
          <a
            className="clicks"
            href={CHROME_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome
          </a>{' '}
          or Microsoft Edge.
        </p>
      </div>
      <div>
        <div className="video-bar">
          <h2 className="overview-heading text-center">Installation</h2>
          <div className="row justify-content-center">
            <div className="col-auto video-style">
              <img
                src={INSTALL_GIF}
                className="video-play"
                alt="Installation Overview"
              />
            </div>
            <div className="col-6 d-grid justify-content-center mt-5">
              <h4 className="overview-sub-heading text-center">
                Steps (Chrome)
              </h4>
              <ul>
                <li>
                  Navigate to the{' '}
                  <a
                    className="clicks"
                    href={CHROME_STORE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chrome Web Store
                  </a>
                </li>
                <li>
                  Click on Add to Chrome <img src={AddToChrome}></img>. Once
                  installed, you’ll be redirected to the{' '}
                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CanDoo login page.
                  </a>
                </li>
                <li>
                  {' '}
                  <p>
                    Upon registration or login, you’ll be redirected to{' '}
                    <a
                      href={CANLII_URL}
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CanLII
                    </a>
                    .
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="video-bar">
          <h2 className="overview-heading text-center">
            Login / Getting Started
          </h2>
          <div className="row justify-content-center">
            <div className="col-auto video-style">
              <img
                src={LOGIN_GIF}
                className="video-play"
                alt="Getting Started Overview"
              />
            </div>
            <div className="col-6 d-grid justify-content-center mt-5">
              <h4 className="overview-sub-heading text-center">Steps</h4>
              <ul>
                <li>
                  Click on{' '}
                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >login</a>{' '}
                </li>
                <li>                  <a
                    className="clicks"
                    href={LOGIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >Create an account</a> or                   <a
                  className="clicks"
                  href={LOGIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >login</a> to an existing account.</li>
                <li>
                  Upon registration or login, you’ll be redirected to                     <a
                      href={CANLII_URL}
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CanLII
                    </a>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="memo-container">
        <h2 className="overview-heading text-center">CanDoo Features</h2>
        <ul>
          <li>
            <strong>                    <a
                      href="https://app.candoo.ai/document/candoo-search"
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CanDoo Search Button
                    </a></strong>{' '}
            <img src={CanDooSearch} alt="CanDoo Search Button" />
            {''}
            <p>
              Optimizes your natural language input for{' '}
              <a
                className="clicks"
                href={CHROME_STORE_URL}
              >
                CanLII’s
              </a>{' '}
              boolean search engine to fetch you more relevant cases.
            </p>
          </li>
          <li>
            <strong> <a
                      href="https://app.candoo.ai/document/candoo-search"
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CanDoo Search History Button
                    </a></strong> {'   '}
            <img src={CanDooSearchHistory} alt="CanDoo Search History Button" />
            <p>
              Revisit your previous search queries using our Search History Tab.
            </p>
          </li>
          <li>
            <strong><a
                      href={CASE_CHAT_URL}
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Conversation Button
                    </a>Conversation Button</strong> {'   '}
            <img src={ConversationBtn} alt="Conversation Button" />
            <p>
              Available on all{' '}
              <a
                className="general-links"
                href="https://www.canlii.org/en/ca/tmob/doc/2017/2017tmob30/2017tmob30.html?ExistingConversation=true"
              >
                CanLII case documents
              </a>
              . Click to access our most powerful features: <a
                      href={CASE_MEMO_URL}
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >memo
                    </a> and <a
                      href={CASE_CHAT_URL}
                      className="clicks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      case chat
                    </a>.
            </p>
          </li>
        </ul>
      </div>
      <DocumentNavigator prev={OVERVIEW} next={MEMO} />
    </Box>
  )
}
