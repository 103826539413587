import React from 'react'
import './ProfilePicture.css'

interface ProfilePictureProps {
  letters: string
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ letters }) => {
  return (
    <div className="profile-picture" style={{ backgroundColor: '#0b3c60' }}>
      {letters.toUpperCase()}
    </div>
  )
}

export default ProfilePicture
