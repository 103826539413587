import CanDooPDF from '../../../assests/images/CanDooText.png'
import { getHostNameUrl } from '../../../utils/setEnvironment'
import UserUtility from '../UserUtility/UserUtility'
import './NavBar.css'
import React from 'react'

export const NavBar: React.FC = () => {
  return (
    <nav className="navbar navigation border-bottom ml-2">
      <div className="container-fluid">
        <div className='navigation-logo'>
          <a href={getHostNameUrl()} className="navbar-brand">
            <img src={CanDooPDF} className="logo-icon" alt="Candoo logo text" />
          </a>
        </div>
        <UserUtility />
      </div>
    </nav>
  )
}
