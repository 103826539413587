import * as React from 'react'
import CircularProgress, {
  type CircularProgressProps
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './Timer.css'

interface TimerProps {
  redirectURL: string
}

function CircularProgressWithLabel (
  props: CircularProgressProps & { value: number }
): JSX.Element {
  return (
    <Box className="circular-progress-container">
      <CircularProgress variant="determinate" {...props} />
      <Box className="circular-progress-label">
        <Typography
          variant="caption"
          component="div"
          color="white"
          className="circular-progress-label-text"
        >{`${Math.round(props.value) / 20}`}</Typography>
      </Box>
    </Box>
  )
}

export default function Timer ({ redirectURL }: TimerProps): JSX.Element {
  const [progress, setProgress] = React.useState(100)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer)
          window.location.href = redirectURL
          return 0
        }
        return prevProgress - 20
      })
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [redirectURL])

  return (
    <div className="timer-container">
      <CircularProgressWithLabel value={progress} />
    </div>
  )
}
