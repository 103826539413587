import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { getUserData, logoutUser } from '../../../api/auth'
import { getHostNameUrl } from '../../../utils/setEnvironment'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import './UserUtility.css'
import { handleDocumentation, handleExtension, navigatePDFChat } from '../../../utils/helpers'

const UserUtility: React.FC = () => {
  const [auth, setAuth] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const hostNameUrl = getHostNameUrl()
  const handleLogin = (): void => {
    window.location.href = `${hostNameUrl}/login`
  }

  const handleProfile = (): void => {
    window.location.href = `${hostNameUrl}/profile`
  }

  const handleLogout = (): void => {
    logoutUser()
      .then((res) => {
        if (res.data.Status === 'Success') {
          window.location.reload()
        } else {
          console.error('Logout Error')
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getUserData()
      .then((res) => {
        if (res.data.Status === 'Success') {
          const userDetails = res.data.userProfile
          setAuth(true)
          setName(userDetails.name)
        } else {
          setAuth(false)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div className="row">
      {auth
        ? (
        <Dropdown className="user-utility align-items-center">
          <div className="row align-items-center">
            <div className="col-auto p-0">
              <ProfilePicture letters={name.substring(0, 2)} />
            </div>
            <div className="col-auto p-0">
              <div>
                <Dropdown.Toggle
                  style={{
                    background: 'transparent',
                    border: 'none',
                    marginRight: '20px',
                    fontSize: '25px',
                    textAlign: 'left'
                  }}
                />
              </div>
            </div>
          </div>
          <Dropdown.Menu
            style={{
              backgroundColor: '#011320',
              borderWidth: 'thick',
              borderStyle: 'double',
              borderColor: 'white'
            }}
          >
            <Dropdown.Item onClick={handleDocumentation}>
              Documentation
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleExtension}>
              CanDoo chrome extension
            </Dropdown.Item>
            <Dropdown.Divider />
             <Dropdown.Item onClick={navigatePDFChat}>CanDoo PDF</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleProfile}>Profile</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
          )
        : (
        <div>
          <button className="btn btn-color-main" onClick={handleLogin}>
            Login
          </button>
        </div>
          )}
    </div>
  )
}

export default UserUtility
