import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  height: '50em',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

interface CustomModalProps {
  displayContent?: string
}
export default function CustomModal ({
  displayContent
}: CustomModalProps): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const handleOpen = (): void => {
    setOpen(true)
  }
  const handleClose = (): void => {
    setOpen(false)
  }
  return (
    <div>
      <Button onClick={handleOpen}>Click to Preview</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img className="img-style" src={displayContent}></img>
        </Box>
      </Modal>
    </div>
  )
}
