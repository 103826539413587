import React from 'react'
import { type UserDetailsProps } from '../ProfileInterface'
import AOLRequirements from '../../Reusable/AreaOfLawRequirements/AOLRequirements'

export const UserDetails: React.FC<UserDetailsProps> = ({
  handleProfileChange,
  profileValues
}) => {
  return (
    <div>
      <h4 className="heading-style">User Details</h4>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="email">
              <strong>Email</strong>
            </label>
          </div>
          <div className="col-8">
            <span
              className="form-control"
            >
              {profileValues.email}
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="username">
              <strong>Username</strong>
            </label>
          </div>
          <div className="col-8">
            <span
              className="form-control"
            >
              {profileValues.username}
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="firstName">
              <strong>First Name<span className="required">*</span></strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Enter First Name"
              value={profileValues.firstName}
              name="firstName"
              className="form-control"
              onChange={(e) => {
                handleProfileChange(e, 'firstName')
              }}
              required
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="lastName">
              <strong>Last Name<span className="required">*</span></strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Enter Last Name"
              value={profileValues.lastName}
              name="lastName"
              className="form-control"
              onChange={(e) => {
                handleProfileChange(e, 'lastName')
              }}
              required
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="organization">
              <strong>Organization<span className="required">*</span></strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Enter your Organization"
              value={profileValues.organization}
              name="organization"
              className="form-control"
              onChange={(e) => {
                handleProfileChange(e, 'organization')
              }}
              required
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="row profile-label">
          <div className="col-4 profile-style">
            <label htmlFor="areaOfLaw">
              <strong>Area of Law <AOLRequirements/></strong>
            </label>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Enter Area of Law"
              value={profileValues.areaOfLaw}
              name="areaOfLaw"
              className="form-control"
              onChange={(e) => {
                handleProfileChange(e, 'areaOfLaw')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
