import React from 'react'
import { type ProfileViewProps } from '../ProfileInterface'
import { UserDetails } from '../UserDetails/UserDetails'
import { UserPreferences } from '../UserPreferences/UserPreferences'
import { ProfileMessages } from '../Messages/Messages'

export const ProfileView: React.FC<ProfileViewProps> = ({
  profileValues,
  displayMessage,
  handleProfileChange,
  info,
  handleSubmit,
  isEdited,
  userInitials,
  handleCancel,
  handleTopicChange,
  selectedTopics,
  handleToggle,
  isAllSubjects,
  displayName
}) => {
  return (
    <div>
      <div>
        <ProfileMessages
          displayName={displayName}
          displayMessage={displayMessage}
          info={info}
          isEdited={isEdited}
        />
        <div className="container-ratio justify-content-center">
          <div className="margin-base profile-container">
            <div className="row justify-content-center">
              <div className="col-md-14 secondary">
                <div className="p-4 box-style">
                  <form onSubmit={handleSubmit}>
                    <UserDetails
                      handleProfileChange={handleProfileChange}
                      profileValues={profileValues}
                    />
                    <UserPreferences
                      handleTopicChange={handleTopicChange}
                      selectedTopics={selectedTopics}
                      handleToggle={handleToggle}
                      isAllSubjects={isAllSubjects}
                    />
                    <div className="row justify-content-center">
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn w-100 rounded-9 btn-color-secondary"
                          disabled={!isEdited}
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-4">
                        <button
                          type="submit"
                          className="btn w-100 rounded-9 btn-color-main"
                          disabled={!isEdited}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileView
