import axios, { type AxiosResponse } from 'axios'
import {
  type ssoRegister,
  type FormValues
} from '../components/Register/RegisterInterface'
import { type PasswordResetInterface } from '../components/ResetPassword/ResetPassword'
import { getApiUrl } from '../utils/setEnvironment'

const accessKeyHeader: string = 'X-Access-Key'
axios.defaults.withCredentials = true

// prevent browser from auto opening of credentials popup
// when receiving 401 with header WWW-Authenticate: Basic
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

const API_URL: string = getApiUrl()

export const getUserData = async (): Promise<AxiosResponse> =>
  await axios.get(`${API_URL}/user-details`)

export const registerUser = async (
  userData: FormValues
): Promise<AxiosResponse> => await axios.post(`${API_URL}/register`, userData)

export interface Credentials {
  email: string
  password: string
}

export interface ProfileInterface {
  email: string
  firstName: string
  lastName: string
  areaOfLaw: string
  organization: string
  topicsOfInterest: string[]
}

// Using fetch because there is a bug with Axios,
// it is not able to read 'response headers'
export async function loginUser (credentials: Credentials): Promise<Response> {
  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(
        `${credentials.email}:${credentials.password}`
      )}`
    },
    credentials: 'include'
  })

  const accessKey = response.headers.get(accessKeyHeader)

  axios.defaults.headers[accessKeyHeader] = accessKey

  return response
}

export const logoutUser = async (): Promise<AxiosResponse> =>
  await axios.get(`${API_URL}/logout`)

export async function findUser (email: string): Promise<AxiosResponse> {
  return await axios.post(
    `${API_URL}/login/find-user`,
    {},
    {
      auth: { username: email, password: '' }
    }
  )
}
export async function resetPassword (
  passwordResetCred: PasswordResetInterface
): Promise<AxiosResponse> {
  return await axios.post(
    `${API_URL}/login/reset-password`,
    {
      secret_code: passwordResetCred.secret_code
    },
    {
      auth: {
        username: passwordResetCred.email,
        password: passwordResetCred.password
      }
    }
  )
}

export async function updateUserProfile (
  profileVariables: ProfileInterface
): Promise<AxiosResponse> {
  return await axios.patch(`${API_URL}/user-details`, {
    email: profileVariables.email,
    firstName: profileVariables.firstName,
    lastName: profileVariables.lastName,
    organization: profileVariables.organization,
    areaOfLaw: profileVariables.areaOfLaw,
    topicsOfInterest: profileVariables.topicsOfInterest
  })
}

export async function ssoLogin (email: string): Promise<Response> {
  const response = await fetch(`${API_URL}/sso/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${email}:${''}`)}`
    },
    credentials: 'include'
  })

  const accessKey = response.headers.get(accessKeyHeader)

  axios.defaults.headers[accessKeyHeader] = accessKey

  return response
}

export const ssoRegistration = async (
  userData: ssoRegister
): Promise<AxiosResponse> =>
  await axios.post(`${API_URL}/sso/register`, userData)

export const sendEmailToUsers = async (): Promise<AxiosResponse> =>
  await axios.post(`${API_URL}/email-users`)

export const fetchFirebaseDetails = async (): Promise<AxiosResponse> =>
  await axios.get(`${API_URL}/sso/firebase`)
