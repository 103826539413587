import React from 'react'
import Drawer from '@mui/material/Drawer'
import LogoText from '../../../assests/images/CanDooText.png'
import { commonStyles } from '../CanDooDoc'
import NestedList from './NestedList'
import Divider from '@mui/material/Divider'
import {
  FAQ,
  EXTENSION_FEATURES
  // pdfChatFeatures
} from '../documentConstants'

export default function SideBar (): JSX.Element {
  const drawerWidth = 290
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          ...commonStyles
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <div>
        <img src={LogoText} className="logo-text-img" />
      </div>
      <NestedList
        title="Getting Started"
        items={['Overview', 'Install CanDoo Extension']}
      />
      <NestedList title="Chrome Extension" items={EXTENSION_FEATURES} />
      <Divider />
      {/* <NestedList title="PDF Chat" items={pdfChatFeatures} /> */}
      <NestedList title="FAQ" items={[FAQ]} />
    </Drawer>
  )
}
