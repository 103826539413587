import React, { useEffect, useState } from 'react'
import './Welcome.css'
import RegisterFormController from '../Register/Controller/RegisterFormController'
import Messages from '../Register/Messages/Messages'
import { RegistrationStatus } from '../Register/RegisterInterface'
import Logo from '../Reusable/Logo/Logo'
import Footer from '../Reusable/Footer/Footer'
import { CANLII_IDENTIFIER } from '../../utils/constants'
import { useAction } from '../../contexts/useAction'

export default function Welcome (): JSX.Element {
  const [registrationStatus, setRegistrationStatus] =
    useState<RegistrationStatus>(RegistrationStatus.Idle)
  const [givenEmail, setGivenEmail] = useState('')
  const [validations, setValidations] = useState({
    password: '',
    terms: ''
  })

  const action = useAction()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const ref = searchParams.get('ref')

    if (ref === CANLII_IDENTIFIER) {
      action.setCanLiiOrigin(true)
    }
  }, [location.search])

  return (
    <div>
      <Logo />
      <div className="container">
        <div className="row center-error">
          <Messages
            registrationStatus={registrationStatus}
            givenEmail={givenEmail}
            validations={validations}
          />
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-7 d-flex">
            <div className="secondary box-style flex-grow-1 d-flex">
              <div className="main-comp welcome-container flex-grow-1">
                <h4 className="heading-style">Welcome</h4>

                <p>Almost there, just need to sign up!</p>

                <p>
                  <b>Getting Started:</b> To begin, please sign up using the
                  registration form on the right. You can create an account with
                  your email or use your Google or Microsoft account for quick
                  access. Once you&#39;re up and running, check out our
                  <i>Documentation</i> to learn what our tool can help you with.
                </p>

                <p>
                  <b>Key Features:</b>
                  <ul>
                    <li>
                      <b>CanLII Plugin:</b> Make your case research easy and
                      accurate. Our plugin is built directly into the CanLII
                      website so that you can access new cases, auto-generate
                      memos, and sift through lengthy documents with ease.
                    </li>
                    <li>
                      <b>Legal PDF Analysis:</b> Have some documents that you
                      want to analyze but they&#39;re not on CanLII (looking at
                      you Westlaw/Lexis users!)? Use our CanDoo models in a
                      secure and safe environment with CanDooPDF to automate
                      anything from case analysis to contract drafting.
                    </li>
                  </ul>
                </p>

                <p>
                  <b>Support:</b> Need help? Our support team is here for you.
                  Visit our FAQ or contact us at{' '}
                  <a href="mailto:hello@candoo.ai">hello@candoo.ai</a>.
                </p>

                <p>
                  Thank you for choosing CanDoo AI. We look forward to
                  supporting you in achieving greater efficiency and success in
                  your legal practice.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-5 mt-4 mt-md-4 mt-lg-0">
            <div className="secondary box-style position-relative">
              <div className="main-comp">
                <h4 className="heading-style">User Registration</h4>
                <RegisterFormController
                  registrationStatus={registrationStatus}
                  setRegistrationStatus={setRegistrationStatus}
                  givenEmail={givenEmail}
                  setGivenEmail={setGivenEmail}
                  validations={validations}
                  setValidations={setValidations}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '5em' }}>
        <Footer />
      </div>
    </div>
  )
}
