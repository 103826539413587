import {
  CANLII_SITE,
  DOCUMENTATION,
  LOCAL_HOST_PDF_CHAT_DEV,
  PDF_CHAT_PROD,
  PDF_CHAT_STAGING,
  PRIMARY_URL,
  SECONDARY_URL,
  STAGING_PRIMARY_URL,
  STAGING_SECONDARY_URL
} from './constants'
import { getHostNameUrl } from './setEnvironment'

export const setCharacterLimit = (characters: string): string => {
  const maxLength = 15

  if (characters.length > maxLength) {
    return characters.slice(0, maxLength)
  } else {
    return characters
  }
}

export const formatName = (inputName: string): string => {
  return inputName.charAt(0).toUpperCase() + inputName.slice(1).toLowerCase()
}

export const defineNames = (userName: string, firstName?: string | null, lastName?: string | null): string => {
  if ((firstName != null && firstName.length > 0) && (lastName != null && lastName.length > 0)) {
    return firstName[0] + lastName[0]
  }

  return userName.slice(0, 2).toUpperCase()
}

export const navigatePDFChat = (): void => {
  const hostNameUrl = getHostNameUrl()
  if ([PRIMARY_URL, SECONDARY_URL].includes(hostNameUrl)) {
    window.location.href = PDF_CHAT_PROD
    return
  }
  if ([STAGING_PRIMARY_URL, STAGING_SECONDARY_URL].includes(hostNameUrl)) {
    window.location.href = PDF_CHAT_STAGING
    return
  }
  window.location.href = LOCAL_HOST_PDF_CHAT_DEV
}

export const handleExtension = (): void => {
  window.location.href = CANLII_SITE
}

export const handleDocumentation = (): void => {
  window.open(DOCUMENTATION, '_blank')
}
