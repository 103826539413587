import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'
import { OVERVIEW, CASE_CHAT_HISTORY } from '../../documentConstants'

export default function FAQ (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div className="overview-container">
      <h3 className='faq-heading'>What types of cases can the AI handle?</h3>
      <p>
        Our AI is trained to handle a wide variety of legal cases across
        different jurisdictions. However, for best results, ensure that the case
        details provided are clear and comprehensive.
      </p>

      <h3 className='faq-heading'>Can I generate memos for multiple cases at once?</h3>
      <p>
        Currently, the feature supports generating memos one case at a time. We
        are working on adding bulk processing capabilities in future updates.
      </p>

      <h3 className='faq-heading'>How accurate is the AI in extracting key case details?</h3>
      <p>
        Our AI is highly accurate and continually improving. However, it is
        always recommended to review the generated memo to ensure all details
        are correctly captured and interpreted.
      </p>

      <h3 className='faq-heading'>Is there a limit to the number of memos I can generate?</h3>
      <p>
        For now, it is unlimited. In the future, depending on your subscription
        plan, there may be a limit to the number of memos you can generate per
        month.
      </p>

      <h2 className='faq-heading'>Contact Support</h2>
      <p>
        If you encounter any issues or have further questions, please contact
        our support team at <a className="links" href="mailto:hello@candoo.ai">hello@candoo.ai</a>
        .
      </p>
      </div>
      <DocumentNavigator prev={CASE_CHAT_HISTORY} next={OVERVIEW} />
    </Box>
  )
}
