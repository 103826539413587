import React, { useState } from 'react'
import { findUser } from '../../api'
import { useNavigate } from 'react-router-dom'
import './ForgotPassword.css'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const [info, setInfo] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setInfo('')
    findUser(email)
      .then((res) => {
        if (res.data.Status === 'Success') {
          navigate(`/login/reset-password?email=${email}`)
        } else {
          alert(res.data.Error)
        }
      })
      .catch((err) => {
        const response = err.response
        if (
          response?.status === 404 ||
          response?.status === 401 ||
          response.data?.Error !== undefined ||
          response.data?.Error !== null
        ) {
          setInfo('failure')
        }
        console.error(err)
      })
  }

  return (
    <div>
      <div className="error-messages-container-new">
        <h2 className="sub-header text-center">Forgot your password?</h2>
        {info === 'success' && (
          <h4 className="success-message text-center">
            We have sent an email to {email} to reset your password.
          </h4>
        )}
        {info === 'failure' && (
          <h4 className="fail-message text-center">
            User not found. Please try again
          </h4>
        )}
        {info === '' && (
          <p className="help-text">
            We’re here to help! Request a password reset with your registered
            email and we’ll email you security code to create your new password.
          </p>
        )}
      </div>
      <div className="container-ratio justify-content-center">
        <div className="margin-base register-container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="bg-white p-4 rounded-9">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your registered Email"
                      name="email"
                      className="form-control rounded-0"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      required
                    ></input>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark w-100 rounded-9 btn-color-main"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgotPassword
