import React from 'react'
import LogoIcon from '../../assests/images/CanDooText.png'
import './LogoHome.css'

interface LogoHomeProps {
  pageHeading: string
}

const LogoHome: React.FC<LogoHomeProps> = ({ pageHeading }) => {
  return (
    <div className="row justify-content-center">
      <div className="logo-home">
        <div className="row center-logo-home">
          <div className="col-auto p-1">
            <img src={LogoIcon} className="logo-icon-home" />
          </div>
          <div className="col-auto p-1 custom-home-heading">
            <h1 className="heading-home"> - {pageHeading}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoHome
