import React from 'react'

export default function NotFound (): JSX.Element {
  return (
        <>
            <div className="d-flex justify-content-center bg-warning">
                <div className='d-flex flex-column'>
                    <div>Page not found</div>
                    <div><a href="/">Back to Home</a></div>
                </div>
            </div>
        </>
  )
}
