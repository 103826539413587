import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faXTwitter,
  faChrome
} from '@fortawesome/free-brands-svg-icons'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'

const Footer: React.FC = () => {
  const canDooLinkedin: string = 'https://www.linkedin.com/company/candoo-ai/'
  const canDooChrome: string =
    'https://chromewebstore.google.com/detail/candoo-find-better-inform/jbhiloilkgcbddifgjconnejjhjpllai'
  const canDooXprofile: string = 'https://twitter.com/CanDooAI'
  const formicAIUrl: string = 'https://formic.ai'
  const canDooUrl: string = 'https://candoo.ai'

  return (
    <div className="footer">
      <br className="border" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-2">
            <div className="row brand justify-content-center">
              <h3 className="footer-company footer-main">COMPANY</h3>
              <div className="col-auto brand">
                <h4>
                  <a
                    href={formicAIUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="brand"
                  >
                    About Us
                  </a>
                </h4>
              </div>
              <div className="col-auto brand footer-company">
                <h4 className="brand footer-main">
                  <a
                    href={canDooUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="brand"
                  >
                    Our Product
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row brand justify-content-center">
              <h3 className="footer-company footer-main">MEDIA</h3>
              <div className="col-auto brand footer-company">
                <a
                  href={canDooLinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="footer-icon"
                    icon={faLinkedin as IconProp}
                  />
                </a>
              </div>
              <div className="col-auto brand footer-company">
                <a
                  href={canDooXprofile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="footer-icon"
                    icon={faXTwitter as IconProp}
                  />
                </a>
              </div>
              <div className="col-auto brand footer-company justify-content-center">
                <a
                  href={canDooChrome}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="footer-icon"
                    icon={faChrome as IconProp}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-2 brand footer-company">
            <div className="rights">
              <h4 className="rights-text">
                © 2024 Formic AI Ltd., All rights reserved.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
