import { type Updater } from 'use-immer'
import { type ProfileInfo, type Defaults } from '../models/Defaults'

export class Action {
  constructor (
    private readonly _status: Defaults,
    private readonly setStatus: Updater<Defaults>
  ) {}

  get status (): any {
    return this._status
  }

  static initialStatus (): Defaults {
    return {
      isCanLiiOrigin: false,
      isLoggedIn: false,
      profile: null,
      activeTitle: 'Overview'
    }
  }

  setCanLiiOrigin (value: boolean): void {
    this.setStatus((prev: Defaults) => {
      prev.isCanLiiOrigin = value
    })
  }

  get isCanLiiOrigin (): boolean {
    return this.status.isCanLiiOrigin
  }

  get isLoggedIn (): boolean {
    return this.status.isLoggedIn
  }

  setLoginStatus (value: boolean): void {
    this.setStatus((prev) => {
      prev.isLoggedIn = value
    })
  }

  get profile (): ProfileInfo {
    return this.status.profile
  }

  setProfile (value: ProfileInfo): void {
    this.setStatus((prev) => {
      prev.profile = value
    })
  }

  // Video Document parameters
  get activeTitle (): string {
    return this.status.activeTitle
  }

  setActiveTitle (value: string): void {
    this.setStatus((prev) => {
      prev.activeTitle = value
    })
  }
}
