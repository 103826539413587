import React from 'react'
import './Loading.css'

interface Props {
  overlay: boolean
}

export function Loading ({ overlay }: Props): JSX.Element {
  if (overlay) return (<LoadingOverlay />)
  return (<LoadingBare />)
}

function LoadingOverlay (): JSX.Element {
  return (
        <div className="loading-overlay">
          <LoadingBare />
        </div>
  )
}

function LoadingBare (): JSX.Element {
  return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border loading-spinner" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
  )
}
